import '@brightspace-ui/core/components/button/button.js';
import '@brightspace-ui/core/components/form/form.js';
import '@brightspace-ui/core/components/html-block/html-block.js';
import '@brightspace-ui/core/components/inputs/input-checkbox.js';
import '@brightspace-ui/core/components/inputs/input-text.js';
import '@brightspace-ui/core/components/list/list.js';
import '@brightspace-ui/core/components/list/list-item.js';
import '@brightspace-ui/core/components/list/list-item-content.js';

import '../../../../../shared/components/inputs/nova-htmleditor/nova-htmleditor.js';
import { selectStyles } from '@brightspace-ui/core/components/inputs/input-select-styles.js';

import { css, html, LitElement, nothing } from 'lit';
import DOMPurify from 'dompurify';
import { heading3Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { inputLabelStyles } from '@brightspace-ui/core/components/inputs/input-label-styles.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';
import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { NovaFormMixin } from '../../../../../shared/mixins/nova-form-mixin/nova-form-mixin.js';

export default class ManageCustomContent extends NovaFormMixin(RequesterMixin(nav(LitElement))) {

  static get properties() {
    return {
      customContent: { type: Array, reflect: false },
      type: { type: String, reflect: false },
      itemDisplay: { type: String, reflect: false },
      tenantId: { type: String, reflect: false },
      language: { type: String, reflect: false },
      _customContentFormData: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      heading3Styles,
      inputLabelStyles,
      selectStyles,
      css`
        :host {
          display: block;
        }

        .add-button {
          margin-top: 10px;
        }

        d2l-input-text {
          padding-bottom: 1.5rem;
        }

        div[slot="actions"] {
          height: 100%;
        }

        .overview {
          margin-top: 10px;
        }

        .remove-button {
          align-self: center;
        }

        .list-items {
          display: flex;
          margin-bottom: 4px;

          > b {
            min-width: 96px;
          }
        }
`,
    ];
  }

  constructor() {
    super();
    this.customContent = [];
    this._customContentFormData = {};
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async updateCustomContent() {
    const customContent = await this.client.getCustomContent(this.tenantId, this.type, this.language, true);
    this.customContent = customContent?.value || [];
  }

  async firstUpdated() {
    this.language = 'en';
    await this.updateCustomContent();
    // Initialize NovaFormMixin properties
    this.hideFormErrorSummary = false;
    this.showErrorToast = true;
  }

  async languageChange(e) {
    this.language = e.target.value;
    await this.updateCustomContent();
  }

  render() {
    return html`
      <select id="language" class="d2l-input-select login-input" name="country" @change=${this.languageChange}>
        <option value="en" selected>English</option>
        <option value="fr">French</option>
      </select>
      <section>
        <h3>New ${this.itemDisplay}</h3>
        <d2l-form @change=${this._changeValue}>
          ${this._inputsTemplate()}
          <d2l-button class="add-button" @click=${this._addCustomContent}>Add</d2l-button>
        </d2l-form>
        <h3>${this.itemDisplay}s</h3>
        <d2l-list @d2l-list-item-position-change=${this._moveItems}>
        ${repeat(this.customContent, (customContent, i) => html`
          <d2l-list-item draggable="true" key=${i}>
            <d2l-list-item-content>
              ${this._detailListTemplate(customContent)}
            </d2l-list-item-content>
            <div slot="actions">
              <d2l-button class="remove-button" @click=${this._removeCustomContent(i)}>Delete</d2l-button>
            </div>
          </d2l-list-item>
        `)}
        </d2l-list>
      </section>
    `;
  }

  async _addCustomContent() {
    const isFormValidated = await this.isFormValidated();
    if (!isFormValidated) return;

    if (this.type === 'faq' && !this._customContentFormData.answer || this.type === 'homepage' && !this._customContentFormData.copy) {
      const requiredField = this.type === 'faq' ? 'answer' : 'copy';
      this.session.toast({ type: 'critical', message: `${requiredField} is required` });
      return;
    }

    this.customContent.push(this._customContentFormData);
    this._dispatchCustomContentUpdated();

    this.resetForm();
    this._customContentFormData = {};

    // Clear the active HTML editor of its current text.
    const htmlEditors = ['answer', 'copy'].map(id => this.shadowRoot.getElementById(id)).filter(x => x);
    htmlEditors.forEach(({ editor }) => {
      editor?.commands.clearContent();
      this.requestUpdate();
    });

    const type = 'default';
    const message = `Added a new ${this.itemDisplay}`;
    this.session.toast({ type, message });
  }

  _changeValue(e) {
    const { id, checked, value } = e.target;

    if (id) {
      if (e.target.hasAttribute('checked')) {
        this._customContentFormData[id] = checked;
      } else if (e.detail?.html) {
        // Ensure that the html tags are not empty before setting the value
        this._customContentFormData[id] = e.detail.text && e.detail.html;
      } else {
        this._customContentFormData[id] = value;
      }
      this.updateFormErrorSummary();
    }
  }

  _detailListTemplate(customContent) {
    const data = Object.keys(customContent).map(key => { return { key, value: customContent[key] }; });
    return html`
      ${repeat(data, ({ key, value }) => html`
        <div class="list-items">
          <b>${key}:</b>
          <d2l-html-block html=${value}></d2l-html-block>
        </div>
      `)}
    `;
  }

  async _dispatchCustomContentUpdated() {
    const homepage = {
      value: this.customContent,
      type: this.type,
      lang: this.language,
      tenantId: this.tenantId,
    };
    await this.client.setCustomContent(this.tenantId, homepage);
  }

  _inputsTemplate() {
    switch (this.type) {
      case 'homepage': {
        return html`
          <d2l-input-text id="header" required label="Header"></d2l-input-text>
          <d2l-input-text id="imageURL" label="Image URL"></d2l-input-text>
            <nova-htmleditor
              .content=${DOMPurify.sanitize(this._customContentFormData.copy)}
              id="copy"
              label="Message copy"
              @nova-htmleditor-update="${this._changeValue}"
              required
            ></nova-htmleditor>
          <d2l-input-checkbox id="overview" class="overview">Overview</d2l-input-checkbox>
        `;
      }
      case 'faq': {
        return html`
          <d2l-input-text id="question" required label="Question"></d2l-input-text>
            <nova-htmleditor
              .content=${DOMPurify.sanitize(this._customContentFormData.answer)}
              id="answer"
              label="Answer"
              @nova-htmleditor-update="${this._changeValue}"
              required
            ></nova-htmleditor>
        `;
      }
    }
    return nothing;
  }

  _moveItems(e) {
    e.detail.reorder(this.customContent, { keyFn: item => this.customContent.indexOf(item).toString() });
    this._dispatchCustomContentUpdated();
  }

  _removeCustomContent(i) {
    return async() => {
      this.customContent.splice(i, 1);
      this.customContent = [...this.customContent];
      await this._dispatchCustomContentUpdated();
      const type = 'default';
      const message = `Successfully deleted ${this.itemDisplay}`;
      this.session.toast({ type, message });
    };
  }

}

window.customElements.define('manage-custom-content', ManageCustomContent);
