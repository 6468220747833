import { NovaModel } from './nova-model.js';
import { RoleCatalogueSchema } from './schema/role-catalogue.js';

export default class RoleCatalogue extends NovaModel {
  constructor(base = {}) {
    super('roleCatalogue', base);
  }

  // TODO: We need to populate the rolePermissions array at runtime at the model level once the Permissions enum is defined

  getSchema() {
    return RoleCatalogueSchema;
  }
}
