import '@brightspace-ui/core/components/empty-state/empty-state-action-button.js';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import '@brightspace-ui/core/components/table/table-col-sort-button.js';

import { heading1Styles } from '@brightspace-ui/core/components/typography/styles.js';
import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';

import {
  NovaPermissionMixin
} from '../../../../../shared/mixins/nova-permission-mixin/nova-permission-mixin.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';
import { PERMISSIONS } from '../../../../../../shared/permissions.js';

import '../../../../../shared/components/general/nova-button/nova-button.js';
import '../../../../../shared/components/general/nova-permission-container/nova-permission-container.js';
import '../../../../../shared/components/dialog/confirmation-dialog/confirmation-dialog.js';
import '../manage-role/manage-role.js';

class EditRoles extends LocalizeNova(NovaPermissionMixin(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      roles: { type: Array },
      _deleteDialogOpen: { type: Boolean },
      _deleteOverview: { type: Object },
      _sortDesc: { type: Object },
      _sortFacet: { type: String },
      _activeTemplate: { type: String },
      _currentRole: { type: Object },
    };
  }

  static get styles() {
    return [
      heading1Styles,
      tableStyles,
      css`
        .empty-state-container {
          height: 100%;
          padding: 0;
          text-align: center;
          vertical-align: middle;
        }

        .empty-state {
          align-items: center;
          display: flex;
          justify-content: center;
        }

`];
  }

  constructor() {
    super();
    // You can see this page with either the ROLES_EDIT or ROLES_VIEW permissions
    this.requiredPermissions = [
      [PERMISSIONS.PERMISSIONS, PERMISSIONS.PERMISSIONS],
    ];
    this.roles = [];
    this._deleteOverview = {};
    this._sortDesc = false;
    this._activeTemplate = 'manageRoles';
  }

  connectedCallback() {
    super.connectedCallback();
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.roles = await this.client.getRoles();
  }

  _handleSort(e) {
    const desc = e.target.hasAttribute('desc');
    this._sortFacet = e.target.id;
    this._sortDesc = !desc;
  }

  _rolesTable() {
    const sortedRoles = this.roles.sort((a, b) => {
      if (this._sortFacet === 'roleName') {
        return this._sortDesc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
      } else if (this._sortFacet === 'allocatedUsers') {
        return this._sortDesc ? b.assignedUsers - a.assignedUsers : a.assignedUsers - b.assignedUsers;
      }
      return 0;
    });
    const emptyState = sortedRoles.length === 0 ?
      html`
        <tr>
          <td colspan="4" class="empty-state-container">
            <div class="empty-state">
              <d2l-empty-state-illustrated illustration-name="desert-road" title-text="${this.localize('edit-roles.table.empty.title')}" description="${this.localize('edit-roles.table.empty.description')}">
                <d2l-empty-state-action-link
                  text="${this.localize('edit-roles.createRole')}"
                  @click="${this._handleAddRoleClick}"
                  href="#"></d2l-empty-state-action-link>
              </d2l-empty-state-illustrated>
            </div>
          </td>
        </tr>` : nothing;

    return html`
      <d2l-table-wrapper sticky-headers>
        <table class="d2l-table">
          <thead>
            <tr>
              <th><d2l-table-col-sort-button id="roleName" ?nosort="${this._sortFacet !== 'roleName'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-roles.table.header.roleName')}</d2l-table-col-sort-button></th>
              <th>${this.localize('edit-roles.table.header.description')}</th>
              <th><d2l-table-col-sort-button id="allocatedUsers" ?nosort="${this._sortFacet !== 'allocatedUsers'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-roles.table.header.allocatedUsers')}</d2l-table-col-sort-button></th>
              <th>${this.localize('edit-roles.table.header.actions')}</th>
            </tr>
          </thead>
          <tbody>
          ${emptyState}
          ${sortedRoles.map(role => html`
            <tr>
              <td>${role.name}</td>
              <td>${role.description}</td>
              <td>${role.assignedUsers}</td>
              <td>
                <nova-permission-container .requiredPermissions="${PERMISSIONS.PERMISSIONS}">
                  <d2l-button-icon
                    class="edit-button"
                    @click="${this._handleEditRoleClick(role)}"
                    icon="tier1:edit"
                    text="${this.localize('edit-roles.button.editRole')}">
                  </d2l-button-icon>
                  <d2l-button-icon
                    class="delete-button"
                    @click="${this._handleDeleteRoleCLick(role)}"
                    icon="tier1:delete"
                    text="${this.localize('edit-roles.button.deleteRole')}">
                  </d2l-button-icon>
                </nova-permission-container>
              </td>
            </tr>
          `)}
          </tbody>
        </table>
      </d2l-table-wrapper>
      ${this._confirmDelete()}
    `;
  }

  _handleDeleteRoleCLick(role) {
    return async() => {
      this._deleteDialogOpen = true;
      const impact = await this.client.getDeleteRoleImpact(role.id);
      this._deleteOverview = {
        id: role.id,
        roleName: role.name,
        ...impact,
      };
    };
  }
  async _deleteDialogClose(e) {
    const { action } = e.detail;
    this._deleteDialogOpen = false;

    if (action === 'done') {
      await this.client.deleteRole();
      this.roles = this.roles.filter(role => role.id !== this._deleteOverview.id);
    }

  }

  _manageRolePageTemplate() {
    if (this._activeTemplate !== 'manageRole') return nothing;
    return html`
      <manage-role @role-saved="${this._handleSaveRole}" .novaRole="${this._currentRole}"></manage-role>
    `;
  }

  _handleSaveRole(e) {
    const { role } = e.detail;
    // find existing role and update it, or add new role
    const existingRole = this.roles.find(r => r.id === role.id);
    if (existingRole) {
      Object.assign(existingRole, role);
    } else {
      this.roles = [...this.roles, role];
    }
    this._activeTemplate = 'manageRoles';
  }

  _handleAddRoleClick() {
    this._activeTemplate = 'manageRole';
    this._currentRole = {};
  }

  _handleEditRoleClick(role) {
    return () => {
      this._currentRole = role;
      this._activeTemplate = 'manageRole';
    };
  }

  handleBackToRoles() {
    this._activeTemplate = 'manageRoles';
  }

  _manageRolesTemplate() {
    if (this._activeTemplate !== 'manageRoles') return nothing;
    return html`<div>
        <h1 class="d2l-heading-1">${this.localize('edit-roles.title')}</h1>
        <p>${this.localize('edit-roles.description')}</p>
        <p><nova-button
        .requiredPermissions="${PERMISSIONS.PERMISSIONS}"
        @click="${this._handleAddRoleClick}"
        dark>${this.localize('edit-roles.createRole')}</nova-button></p>
        ${this._rolesTable()}
      </div>`;
  }

  _returnToRolesButton() {
    if (this._activeTemplate === 'manageRoles') return nothing;
    return html`
      <d2l-link @click="${this.handleBackToRoles}">← Return to roles</d2l-link>`;
  }

  render() {
    return html`
        ${this._returnToRolesButton()}
        ${this._manageRolePageTemplate()}
        ${this._manageRolesTemplate()}
    `;
  }

  _confirmDelete() {
    return html`
      <confirmation-dialog
        ?opened=${this._deleteDialogOpen}
        type="removeRoleConfirmation"
        .data="${this._deleteOverview}"
        @d2l-dialog-close=${this._deleteDialogClose}>
      </confirmation-dialog>`;
  }

}

window.customElements.define('edit-roles', EditRoles);

